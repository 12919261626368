<template>
  <b-modal v-model="localIsActive" :title="title" hide-footer centered size="xl" @hidden="handleHidden" no-close-on-backdrop>
    <div v-if="typeNames">
      <div v-for="(typeName, typeKey) in typeNames" :key="typeKey">
        <h4 class="mb-1">{{ typeName }}</h4>
        <b-table small bordered :items="existingNominations[typeKey]" :fields="nominationFields" v-if="existingNominations[typeKey] && existingNominations[typeKey].length">
          <template #cell(reviewer)="data">
            {{ data.item.reviewer.fullName }}
          </template>
          <template #cell(engagement)="data">
            {{ data.item.engagement }} 
          </template>
          <template #cell(createdBy)="data">
            {{ data.item.createdBy.fullName }} 
            <b-badge :variant="data.item.createdBy.role === 'user' ? 'light-primary' : 'light-success'">
              {{ data.item.createdBy.role }}
            </b-badge>
            <small> - <i>{{ timeAgo(data.item.createdAt) }}</i></small>
          </template>
          <!-- <template #cell(createdAt)="data">
            {{ timeAgo(data.item.createdAt) }}
          </template> -->
          <template #cell(status)="data">
            <span :class="statusClass(data.item.status)" class="status-dot"></span>
          </template>
          <template #cell(actions)="data">
            <span v-b-tooltip.hover.top="tooltipTextUnnominate(data.item.status)">
              <b-button 
                variant="outline-danger" 
                @click="unnominate(data.item)" 
                class="py-25 my-0 px-75"
                :disabled="data.item.status === 'Submitted'"
              >
                Unnominate
              </b-button>
            </span>
          </template>
        </b-table>

        <!-- Nomination fields -->
        <div v-for="(nomination, index) in nominations[typeKey]" :key="`${typeKey}-${index}`" class="d-flex align-items-center mb-2">
          <v-select v-model="nomination.user" :options="advisorOptions" placeholder="Select User" class="mr-2 flex-grow-1" :class="{'is-invalid': !nomination.user}" />
          <b-form-input v-model="nomination.engagement" placeholder="Engagement" class="flex-grow-0" style="flex-basis: 60%;" :class="{'is-invalid': !nomination.engagement}" />
          <b-button variant="outline-danger" @click="$emit('remove-nomination-row', typeKey, index)" v-if="nominations[typeKey].length > 0" class="ml-1 py-25 my-0 px-75">-</b-button>
        </div>
        <div class="d-flex justify-content-center mb-2">
          <b-button 
            v-if="canNominate[typeKey] && nominations[typeKey].length < nominationLimit(typeKey)" 
            variant="outline-primary" 
            @click="$emit('add-nomination-row', typeKey)" 
            class="py-75 my-0 px-75" 
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span>Add '{{ typeName }}' Nomination</span>
          </b-button>
        </div>
        <hr class="mt-2" v-if="typeKey !== Object.keys(typeNames).pop()"/>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <span v-if="Object.values(nominations).some(typeNominations => typeNominations.length > 0)" v-b-tooltip.hover.top="tooltipTextSave">
          <b-button :disabled="!canSave" variant="success" class="py-50 my-0 px-1" @click="saveNominations">Save</b-button>
        </span>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BFormInput, BButton, BTable, BBadge, VBTooltip } from 'bootstrap-vue'
import { timeAgo } from '../../utils';
import vSelect from 'vue-select'
import { title } from 'process';

export default {
  components: {
    BModal,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isActive: Boolean,
    title: String,
    typeNames: Object,
    advisorOptions: {
      type: Array,
      required: true,
    }, 
    nominations: Object,
    userId: String,
  },
  data() {
    return {
      allowedAdminNominationNumber: 3,
      existingNominations: {}, // Add existingNominations data property
      nominationFields: [
        { key: 'reviewer', label: 'Reviewer' },
        { key: 'engagement', label: 'Engagement' },
        { key: 'createdBy', label: 'Created By' },
        // { key: 'createdAt', label: 'Created' },
        { key: 'status', label: '' },
        { key: 'actions', label: 'Actions' }
      ],
      canNominate: {} // Add canNominate data property
    }
  },
  computed: {
    localIsActive: {
      get() {
        return this.isActive
      },
      set(value) {
        this.$emit('update:isActive', value)
      }
    },
    canSave() {
      const hasRows = Object.values(this.nominations).some(typeNominations => typeNominations.length > 0);
      const allFieldsFilled = Object.values(this.nominations).every(typeNominations => 
        typeNominations.every(nomination => nomination.user && nomination.engagement)
      );
      return hasRows && allFieldsFilled;
    },
    tooltipTextSave() {
      if (!this.canSave) {
        return 'Please fill in all fields before saving.';
      }
      return '';
    },
    nominationLimit() {
      return (typeKey) => {
        const adminNominations = this.existingNominations[typeKey]?.filter(nomination => nomination.isAdmin).length || 0;
        return this.allowedAdminNominationNumber - adminNominations;
      }
    }
  },
  watch: {
    localIsActive(newVal) {
      if (!newVal) {
        this.handleHidden()
      } else {
        this.fetchExistingNominations(this.userId)
        this.fetchCanNominate(this.userId) 
      }
    }
  },
  methods: {
    handleHidden() {
      this.existingNominations = {}
      this.$emit('hidden')
    },
    fetchExistingNominations(userId) {
      this.$http.get(`/users/${userId}/nominations`)
        .then(response => {
          this.existingNominations = response.data
        })
        .catch(error => console.error('Error fetching existing nominations:', error))
    },
    fetchCanNominate(userId) {
      this.$http.get(`/users/admin/${userId}/canNominate`)
        .then(response => {
          this.canNominate = response.data
        })
        .catch(error => console.error('Error fetching canNominate data:', error))
    },
    unnominate(nomination) {
      const nominationId = nomination._id;
      this.$bvModal
        .msgBoxConfirm(`Delete the nomination for ${ nomination.reviewer.fullName } with engagement ${ nomination.engagement }?`, {
          title: 'Are you sure?',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$http.delete(`/surveys/${nominationId}`)
              .then(() => {
                this.fetchExistingNominations(this.userId) // Refresh existing nominations after unnomination
              })
              .catch(error => console.error('Error unnominate:', error))
          }
        })
        .catch(err => console.error('Error confirming unnominate:', err))
    },
    statusClass(status) {
      switch (status) {
        case 'Submitted':
          return 'status-dot--success'
        case 'In review':
          return 'status-dot--info'
        case 'Pending':
          return 'status-dot--warning'
        default:
          return ''
      }
    },
    tooltipTextUnnominate(status) {
      if (status === 'Submitted') {
        return 'You cannot unnominate a submitted survey.';
      }
      return '';
    },
    saveNominations() {
      const surveys = []
      for (const typeKey in this.nominations) {
        this.nominations[typeKey].forEach(nomination => {
          surveys.push({
            type: typeKey,
            reviewer: nomination.user.value,
            engagement: nomination.engagement
          })
        })
      }
      const payload = {
        reviewee: this.userId,
        surveys
      }
      this.$http.post('/surveys/admin', payload)
        .then(() => {
          this.$swal({
            title: 'Nominations sent successfully!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
          this.$emit('save-nominations')
          this.localIsActive = false
        })
        .catch(err => {
          const errorMessage = (err.response && err.response.data && err.response.data.error) || err || 'Error saving nominations.'
          console.error('Error saving nominations:', errorMessage)
          this.$swal({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        })
    },
    timeAgo,
  },
  mounted() {
  }
}
</script>

<style scoped>
.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-dot--success {
  background-color: #28a745; /* Bootstrap success color */
}

.status-dot--info {
  background-color: #17a2b8; /* Bootstrap info color */
}

.status-dot--warning {
  background-color: #ffc107; /* Bootstrap warning color */
}

.is-invalid {
  border-color: #dc3545 !important; /* Bootstrap danger color */
}
</style>